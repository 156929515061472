import React from 'react'

export type ButtonProps = {
  enabled: boolean
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

export const NextButton = ({ enabled, onClick }: ButtonProps) => (
  <button
    className="absolute outline-none cursor-pointer bg-transparent touch-manipulation z-[1] top-1/2 -translate-y-1/2 border-none w-[30px] h-[30px] justify-center align-center fill-hotPink-500 right-[27px] disabled:cursor-default disabled:opacity-20"
    id="embla__button embla__button--next"
    onClick={onClick}
    disabled={!enabled}
    aria-label="Next slide"
  >
    <svg className="w-full h-full" viewBox="0 0 238.003 238.003">
      <path d="M181.776 107.719L78.705 4.648c-6.198-6.198-16.273-6.198-22.47 0s-6.198 16.273 0 22.47l91.883 91.883-91.883 91.883c-6.198 6.198-6.198 16.273 0 22.47s16.273 6.198 22.47 0l103.071-103.039a15.741 15.741 0 0 0 4.64-11.283c0-4.13-1.526-8.199-4.64-11.313z" />
    </svg>
  </button>
)

export default NextButton
