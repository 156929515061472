import React, { useState, useEffect, useCallback } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import DotButton from './dot-button'
import NextButton from './next-button'
import PrevButton from './prev-button'

export type CarouselProps = {
  slides?: React.ReactNode[]
  id: string
}

const Carousel = ({ slides, id }: CarouselProps) => {
  const [viewportRef, embla] = useEmblaCarousel({
    skipSnaps: false,
    dragFree: true,
    containScroll: 'trimSnaps',
    inViewThreshold: 1,
    align: 'center',
  })
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([])

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla])
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla])
  const scrollTo = useCallback((index: number) => embla && embla.scrollTo(index), [embla])

  const onSelect = useCallback(() => {
    if (!embla) return
    setSelectedIndex(embla.selectedScrollSnap())
    setPrevBtnEnabled(embla.canScrollPrev())
    setNextBtnEnabled(embla.canScrollNext())
  }, [embla, setSelectedIndex])

  useEffect(() => {
    if (!embla) return
    onSelect()
    setScrollSnaps(embla.scrollSnapList())
    embla.on('select', onSelect)
  }, [embla, setScrollSnaps, onSelect])

  return (
    <>
      <div
        key={id}
        className="relative overflow-hidden pb-7 cursor-grab active:cursor-grabbing"
        id="embla__viewport"
        ref={viewportRef}
      >
        <div className="flex gap-x-6 md:gap-x-12 max-w-min mx-auto" id="embla__container">
          {slides &&
            slides.map((slide, i) => (
              <div className="relative 2" id="embla__slide" key={i}>
                <div className="relative min-h-full flex flex-col pt-4" id="embla__slide__inner">
                  {slide}
                </div>
              </div>
            ))}
        </div>
      </div>

      {scrollSnaps.length > 1 && (
        <div className="relative flex list-none justify-center pt-2.5 max-w-lg mx-auto" id="embla__dots">
          {scrollSnaps.map((_, index) => (
            <DotButton key={index} index={index} selected={index === selectedIndex} onClick={() => scrollTo(index)} />
          ))}

          <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
          <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
        </div>
      )}
    </>
  )
}

export default Carousel
