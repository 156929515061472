import React from 'react'

export type DotButtonProps = {
  index: number
  selected: boolean
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

export const DotButton = ({ index, selected, onClick }: DotButtonProps) => (
  <button
    id="embla-dot"
    className={`relative flex align-center bg-transparent cursor-pointer p-0 after:bg-hotPink-500  outline-none border-none w-[30px] h-[30px] -top-[20px] mr-[7.5px] ml-[7.5px]  after:w-full after:h-[4px] after:rounded-sm after:content-[''] ${
      selected ? 'after:opacity-100' : 'after:opacity-20'
    }`}
    type="button"
    onClick={onClick}
    aria-label={`Slide ${index + 1}`}
  />
)

export default DotButton
